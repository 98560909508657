import React from "react"

import CoachLayout from "@components/coach-layout"
import SEO from "@components/seo"
import appOfTheDayImage from '@images/app-of-the-day-$white.svg'
import appRatingBadgeImage from '@images/app-rating-badge-$white.svg'
import dailyHomeworkImage from '@images/coach/harper-coach-daily-homework.jpg'
import forYouImage from '@images/coach/harper-coach-for-you.jpg'
import messagingImage from '@images/coach/harper-coach-messaging.jpg'
import newSchoolImage from '@images/coach/harper-coach-new-school.jpg'
import videoLogImage from '@images/coach/harper-coach-video-log.jpg'
import weeklyCallImage from '@images/coach/harper-coach-weekly-call.jpg'
import welcomeKitImage from '@images/coach/harper-coach-welcome-kit.jpg'
import coachHeroImage from '@images/coach/harper-coach-hero.jpg'

const CoachPage = () => (
  <CoachLayout bodyClassName='coach'>
    <SEO 
      title="Coach"
      description="Harper Coach is 1:1 remote training with a real dog trainer, powered by an awesome app, dedicated to helping you and your pup reach your goals." 
    />

    <section className="stripe stripe--hero">
      <div className="container">
        <div className="heroWrap">
          <span className="logoLockup"><a href="/" className="harperWordmark">Harper</a> <a href="#" className="productWordmark">Coach</a> <span className="betaBadge">Beta</span></span>
          <h3 className="processSubhead">1-on-1 remote dog training</h3>
          <h1 className="sectionTitle">Unlock your dog’s potential, your way</h1>
          <p className="prose">Harper Coach is a real dog trainer, powered by an awesome app, dedicated to helping you and your pup reach your goals.</p>
          <a href="/coach/signup/" className="btn btn--primary">Sign up now</a>
        </div>
        <img className="coachHeroImage" src={coachHeroImage} />
      </div>
    </section>

    <section className="stripe stripe--reverse stripe--process" id="tech">
      <div className="container">
        <div className="processWrap">
          <h2 className="sectionTitle">The future of dog training is finally here</h2>
          <div className="techBadges">
            <img className="techBadge" src={appOfTheDayImage} />
            <img className="techBadge" src={appRatingBadgeImage} />
          </div>
        </div>
      </div>
    </section>
  
    <section className="stripe stripe--process">
      <div className="container">
        <div className="processWrap">
          <div className="processItemBenefit">
            <span className="processNumber">01</span>
            <h3 className="processSubhead">Positive reinforcement</h3>
            <h2 className="sectionTitle">New-school training techniques</h2>
          </div>
          <div className="processItemEvidence">
            <img className="" src={newSchoolImage} />
            <p className="prose">Your coach will teach you to use the latest training and behavior science best practices, utilizing positive reinforcement and choice-based training.</p>
            <ul className="detailList">
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Training that begins with building a bond and trust.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Teach your dog to think for himself and make good decisions.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">100% force-free.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section className="stripe stripe--process">
      <div className="container">
        <div className="processWrap">
          <div className="processItemBenefit">
            <span className="processNumber">02</span>
            <h3 className="processSubhead">Welcome kit</h3>
            <h2 className="sectionTitle">Get off to a flying start</h2>
          </div>
          <div className="processItemEvidence">
            <img className="" src={welcomeKitImage} />
            <p className="prose">The Harper Training Kit gives you everything you need to get off to a fast training start.</p>
            <ul className="detailList">
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">High- and low-value training treats.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Training toys including a long lasting food puzzle and a tug toy.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Camera tripod for filming your training sessions.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section className="stripe stripe--process">
      <div className="container">
        <div className="processWrap">
          <div className="processItemBenefit">
            <span className="processNumber">03</span>
            <h3 className="processSubhead">Training plan</h3>
            <h2 className="sectionTitle">A plan built for you&mdash;not the masses</h2>
          </div>
          <div className="processItemEvidence">
            <img className="" src={forYouImage} />
            <p className="prose">Your pup is unique, your home setup is unique, your routine is unique, so your training plan should be unique too. Whatever your situation, your coach has you covered.</p>
            <ul className="detailList">
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Custom plan built by your coach and reviewed daily.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Plan fit to your life and not the other way around.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Big dog, small dog, high-energy, low-energy, city home, rural home, at home all day or away at work&mdash;there&rsquo;s a plan for you.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section className="stripe stripe--process">
      <div className="container">
        <div className="processWrap">
            <div className="processItemBenefit">
            <span className="processNumber">04</span>
            <h3 className="processSubhead">Training sessions</h3>
            <h2 className="sectionTitle">Daily training homework</h2>
          </div>
          <div className="processItemEvidence">
            <img className="" src={dailyHomeworkImage} />
            <p className="prose">Your coach will give you daily training tasks along with demonstrations broken up into short step-by-step videos.</p>
            <ul className="detailList">
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Know exactly what to do each day.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Training library gives you a step-by-step video demonstrations.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Never get stuck. Your coach is just a message away.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section className="stripe stripe--process">
      <div className="container">
        <div className="processWrap">
            <div className="processItemBenefit">
            <span className="processNumber">05</span>
            <h3 className="processSubhead">Accountability</h3>
            <h2 className="sectionTitle">Video training log and coach review</h2>
          </div>
          <div className="processItemEvidence">
            <img className="" src={videoLogImage} />
            <p className="prose">You&rsquo;ll take a video of your training sessions and log them in Harper along with important details for your coach to review.</p>
            <ul className="detailList">
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Get daily feedback and training guidance.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">The Harper app makes logging intuitive and easy.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Your log is there for you to review and see all the progress you&rsquo;ve made.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section className="stripe stripe--process">
      <div className="container">
        <div className="processWrap">
          <div className="processItemBenefit">
            <span className="processNumber">06</span>
            <h3 className="processSubhead">Continuous contact</h3>
            <h2 className="sectionTitle">Message your coach about all things dog</h2>
          </div>
            <div className="processItemEvidence">
            <img className="" src={messagingImage} />
            <p className="prose">Training is just one part of dog parenting, and your coach is here to help with it all. Health questions, gear recommendations, what dog food to buy&mdash;your coach wants to help.</p>
            <ul className="detailList">
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Like having a smart dog friend who you can message anytime.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">There are no dumb questions.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Easier than Googling, more trustworthy than Facebook groups.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section className="stripe stripe--process">
      <div className="container">
        <div className="processWrap">
          <div className="processItemBenefit">
            <span className="processNumber">07</span>
            <h3 className="processSubhead">Set goals</h3>
            <h2 className="sectionTitle">Weekly video calls to stay on track</h2>
          </div>
          <div className="processItemEvidence">
            <img className="" src={weeklyCallImage} />
            <p className="prose">You&rsquo;ll meet with your coach once a week to discuss how the training is going, what&rsquo;s working and what&rsquo;s not, and any adjustments you might want to make to your training plan.</p>
            <ul className="detailList">
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Flexible scheduling. Meet with your coach when it’s convenient for you.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Your coach will do all the planning. You just need to show up.</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Get a training summary from your coach that will help you visualize and celebrate all of your progress.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section className="stripe stripe--alt" id="checklist">
      <div className="container">
        <div className="processWrap">
          <div className="processItemBenefit">
            <h2 className="sectionTitle">What you get</h2>
          </div>
          <div className="processItemEvidence">
          <ul className="detailList">
            <li className="detail">
              <span className="icon"></span>
              <span className="detail__text">
                <span className="detail__title">Custom training plan</span>
                <span className="detail__description">Flexible scheduling. Meet with your coach when it’s convenient for you.</span>
              </span>
            </li>
            <li className="detail">
              <span className="icon"></span>
              <span className="detail__text">
                <span className="detail__title">Welcome kit</span>
                <span className="detail__description">Your welcome kit contains everything you need to train including treats, toys, and even a tripod to help you film your training sessions.</span>
              </span>
            </li>
            <li className="detail">
              <span className="icon"></span>
              <span className="detail__text">
                <span className="detail__title">Daily to-do&rsquo;s</span>
                <span className="detail__description">Everyday you&rsquo;ll open the app to find the training tasks your coach has set up for you. You&rsquo;ll know exactly what to work on.</span>
              </span>
            </li>
          </ul>
          <ul className="detailList">
            <li className="detail">
              <span className="icon"></span>
              <span className="detail__text">
                <span className="detail__title">Video training log</span>
                <span className="detail__description">An easy way to log your training sessions and share them with your coach.</span>
              </span>
            </li>
            <li className="detail">
              <span className="icon"></span>
              <span className="detail__text">
                <span className="detail__title">Daily training feedback</span>
                <span className="detail__description">Your coach will review your training log each day and give you feedback. Every dog is different so daily adjustments are critical.</span>
              </span>
            </li>
            <li className="detail">
              <span className="icon"></span>
              <span className="detail__text">
                <span className="detail__title">Weekly 30-minute video call</span>
                <span className="detail__description">Every week you&rsquo;ll have a chance to dive deeper with your coach on your training.</span>
              </span>
            </li>
          </ul>
          <ul className="detailList">
            <li className="detail">
              <span className="icon"></span>
              <span className="detail__text">
                <span className="detail__title">24/7 messaging</span>
                <span className="detail__description">Never get stuck. Your coach is just a message away and can help with anything related to your dog. Dog parenting is more than just training!</span>
              </span>
            </li>
            <li className="detail">
              <span className="icon"></span>
              <span className="detail__text">
                <span className="detail__title">Training library of 30+ courses and 200 training games and articles</span>
                <span className="detail__description">Once you get the hang of training, you’ll want to do more. With Harper you’ll have a library of tips and tricks and best practices right in your pocket.</span>
              </span>
            </li>
          </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section className="stripe" id="price">
      <div className="container">
        <h3 className="processSubhead">Pricing</h3>
        <h2 className="sectionTitle">One month of Harper Coach costs the same as just one hour of the old way</h2>
        <div className="priceChart">
          <div className="priceChart__harper">
            <h3 className="processSubhead">The future</h3>
            <h2 className="chapterTitle">Harper Coach</h2>
            <ul className="detailList">
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Your coach is always there to support you</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Video lessons available anytime, anywhere</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Accountability and convenience</span>
              </li>
              <li className="detail">
                <span className="detail__text detail__price">$119 per <span className="detail__price__highlight">month</span></span>
              </li>
              
            </ul>
          </div>
          <div className="priceChart__irl">
            <h3 className="processSubhead">The past</h3>
            <h2 className="chapterTitle">In-Person Lessons</h2>
            <ul className="detailList">
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Get help on <em>their</em> schedule</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">You stop learning once you leave</span>
              </li>
              <li className="detail">
                <span className="icon"></span>
                <span className="detail__text">Inconvenient and expensive</span>
              </li>
              <li className="detail detail__price">
                <span className="detail__text">$400 per <span className="detail__price__highlight">month</span></span>
                <span className="detail__price__context">4 sessions x $100 per session</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className="stripe stripe--alt" id="close">
      <div className="container">
        <h3 className="processSubhead">Just $4 a day</h3>
        <h2 className="sectionTitle">Try Harper Coach risk-free</h2>
        <p className="prose">First week is free. Your card won’t be charged until after your intro call and first week of training.</p>
        <a href="/coach/signup/" className="btn btn--primary">Sign up now</a>
      </div>
    </section>
  </CoachLayout>
);

export default CoachPage
